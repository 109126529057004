<!-- 票据店铺 -->
<template>
	<div :class="themeClass">
		<div class="margin-t-20">
			<div class="margin-t-15 flex-row-start-between">
				<div class="font-size18 font-color-333 font-weight700 ">
					{{isSelect=='RechargeTicket'?'票据充值':(isSelect=='ConsolidationTicket'?'票据合并':'票据退款')}}
				</div>
				<!-- <div v-if="isSelect=='RechargeTicket'" class="background-color-theme add-recharge-tickte flex-row-center-center pointer"
				 @click="addRechargeTicket">
					新增票据充值
				</div> -->
			</div>
			<div class="margin-t-20 recharge-wallet-box flex-row-wrap" v-loading="shopLoading">
				<div v-for="(item,index) in tickteShopData" :key="index" class="recharge-tickte-shop-card border-F2F2F2 margin-b-15 bg-fff pointer"
				 :class="(index+1)%3!=0?'margin-r-15':''" @click="clickTicket">
					<div class="flex-row">
						<div class="recharge-tickte-shop-img-box border-F2F2F2 margin-r-6">
							<!-- <el-image :src="item.url">
							</el-image> -->
							<imageBox :width='40' :height='40' :borderRidus='4' :src="item.fShopLogoPath"></imageBox>
						</div>
						<div>
							<div class="font-size14 font-color-000 hide-text shop-name-box">{{item.fShopName}}</div>
							<div class="recharge-tickte-shop-tip flex-row-center-center font-size12 margin-t-5">{{item.fShopTypeID==1?'自营':'店铺'}}</div>
						</div>
					</div>
					<div v-if="isSelect=='RechargeTicket'" class="flex-column-start-end">
						<div class="border-color-theme color-theme margin-t-2 font-size13 recharge-tickte-button flex-row-center-center pointer"
						 @click.stop="rechargeTicket(item)">
							充值
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<script>
	import imageBox from '@/components/imageBox/imageBox.vue'
	import {
		mapGetters
	} from "vuex";
	export default {
		components: {
			imageBox
		},
		data() {
			return {
				isSelect: "",
				searchText: "", //查询条件
				mainPage: 1, //页数
				mainmMxResultCount: 10, //每页条数
				shopLoading: false,
				tickteShopData: []
			};
		},
		watch: {
			//如果路由有变化，会再次执行该方法
			"$route": {
				handler(route) {
					if (this.$route.query.type) {
						this.isSelect = this.$route.query.type;
					}
					// console.log(this.isSelect);
				},
				deep: true
			},
		},
		mounted() {
			this.isSelect = this.$route.query.type;
			this.getShopList()
		},
		created() {},
		computed: {
			...mapGetters(["getThemeName"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			},
		},
		methods: {
			//获取店铺
			getShopList() {
				this.shopLoading = true;
				this.ApiRequestPost('api/mall/ebshop/baseinfo/get-enable-list', {
					filter: this.searchText,
					// maxResultCount: this.mainmMxResultCount,
					// skipCount: (this.mainPage - 1) * this.mainmMxResultCount
				}).then(result => {
						this.shopLoading = false;
						console.log(result);
						let arr = result.obj.items
						arr.forEach((item, i) => {
							if (item.fShopUnitID == '-1') {
								arr.splice(i, 1);
							}
						})
						this.tickteShopData = arr;
					},
					error => {
						this.shopLoading = false;
					}
				)
			},
			//新增票据充值
			addRechargeTicket() {
				// console.log("新增票据充值");
				this.$router.push({
					path: '/businessme/RechargeTicketForm',
					query: ''
				});
			},
			//选中卡片跳转
			clickTicket() {
				let path = "";
				if (this.isSelect == 'RechargeTicket') {
					path = "/businessme/TicketDetailed"
				} else if (this.isSelect == 'ConsolidationTicket') {
					path = "/businessme/ConsolidationTicket"
				} else if (this.isSelect == 'RefundTicket') {
					path = "/businessme/RefundTicket"
				}
				this.$router.push({
					path: path,
					query: ''
				});
			},
			//充值
			rechargeTicket(e) {
				let obj = {
					shopName: e.fShopName,
					shopID: e.fShopUnitID
				}
				this.$router.push({
					path: '/businessme/RechargeTicketForm',
					query: obj
				});
			}
		}

	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed("themes_color");
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed("themes_color");
		}
	}

	.recharge-wallet-box {
		width: 915px;
	}

	.add-recharge-tickte {
		padding: 0;
		width: 110px;
		height: 30px;
		border-radius: 2px;
	}

	.recharge-tickte-shop-card {
		width: 273px;
		padding: 15px 10px;
		border-radius: 10px;
	}

	.recharge-tickte-shop-img-box {
		width: 40px;
		height: 40px;
		border-radius: 5px;
	}

	.recharge-tickte-shop-tip {
		width: 37px;
		height: 17px;
		background-color: #FF0000;
		color: #FFFFFF;
		border-radius: 5px;
	}

	.recharge-tickte-button {
		padding: 0;
		width: 73px;
		height: 22px;
		border-radius: 26px;
	}

	.shop-name-box {
		width: 222px;
		height: 18px;
	}
</style>
